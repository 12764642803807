import React, { useEffect } from "react";
const facebookAppId = "101243261456764";
const CustomerChat = () => {
	useEffect(() => {
		window.fbAsyncInit = function () {
			window.FB.init({
				xfbml: true,
				version: "v10.0",
			});
		};
		(function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s);
			js.id = id;
			js.src = "https://connect.facebook.net/en_GB/sdk/xfbml.customerchat.js";
			fjs.parentNode.insertBefore(js, fjs);
		})(document, "script", "facebook-jssdk");
	});
	return (
		<>
			<div
				className="fb-customerchat"
				attribution="page_inbox"
				page_id={facebookAppId}
			></div>
		</>
	);
};

export default CustomerChat;
