/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { createContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import "@fontsource/inter";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import CustomerChat from "./FacebookChat";

import "./layout.css";

const globalContext = {
	displayEasterEggs: false
}

export const GlobalContext = createContext();

const Layout = ({ children, title }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	const siteTitle = data.site.siteMetadata.title;
	const currentYear = new Date().getFullYear();

	return (
		<GlobalContext.Provider value={globalContext}>
			<a href='#main' className="skip-link flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md  md:py-4 md:text-lg md:px-10 text-white bg-yellow-500 hover:bg-yellow-600">Skip to content</a>
			{children}
			<footer className="bg-gray-800">
				<CustomerChat />
				<div className="max-w-7xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
					<div className="flex justify-center space-x-6 md:order-2"></div>
					<div className="py-8 md:mt-0 md:order-1">
						<p className="text-center text-base text-gray-400 mb-0">
							&copy; {currentYear} {siteTitle}
						</p>
					</div>
				</div>
			</footer>
		</GlobalContext.Provider>
	);
};

export default Layout;
